
/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import { setTitle, handle429 } from '@util/util.ts';
import { app } from '@config';
import ButtonGroup from '../../../fruitpackage/components/ButtonGroup.vue';
import Slider from '../../../fruitpackage/components/Slider.vue';
import CardDefault from '@part/elements/CardDefault.vue';
import CardLong from '@part/elements/CardLong.vue';
import ImageTextBlock from '@part/blocks/ImageTextBlock.vue';
import Overlay from '@part/layout/Overlay.vue';
import CrossCircleSvg from '@svg/CrossCircle.vue';
import ArrowBackSvg from '@svg/ArrowBack.vue';
import ArrowForwardSvg from '@svg/ArrowForward.vue';
import RefreshSvg from '@svg/Refresh.vue';
import ExternalSvg from '@svg/External.vue';
import FacebookSvg from '@svg/Facebook.vue';
import InstagramSvg from '@svg/Instagram.vue';
import PinGhostSvg from '@svg/PinGhost.vue';
import ClockSvg from '@svg/Clock.vue';
import InfoSvg from '@svg/Info.vue';
import axios from 'axios';
import DetailLayout from '@part/elements/DetailLayout.vue';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
  components: {
    DetailLayout,
    CardDefault,
    CardLong,
    ImageTextBlock,
    ButtonGroup,
    Slider,
    Overlay,
    ArrowBackSvg,
    ArrowForwardSvg,
    CrossCircleSvg,
    RefreshSvg,
    ExternalSvg,
    FacebookSvg,
    InstagramSvg,
    PinGhostSvg,
    ClockSvg,
    InfoSvg
  },
  data() {
    return {
      pageData: null,
      backLink: {
        text: 'Thema`s',
        url: '/themas'
      }
    };
  },
  props: {
    id: {
      required: true,
      default: 0
    }
  },
  methods: {},
  mounted() {
    let $this = this;
    axios
      .get(`${app.api.api}/theme/${$this.id}`)
      .then(response => {
        $this.pageData = response.data.data;
        setTitle(response);
      })
      .catch(error => {
        console.log(`🔥${error}🔥`);
        handle429(this, error);
      });
  }
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class ThemeDetail extends Vue {}
